<template>
  <l-layer-group
    :name="label"
    :layerType="type"
    :enabled="enabled"
    :options="options"
    v-if="show"
  >
    <template v-if="linked && travels && enabled">
      <l-polyLine
        v-for='(travel, travelId) in travels'
        :key="travelId"
        :markers="travel.markers"
        :focused="focusLink && travel.focused"
      />
    </template>
    <template v-else-if="linked && linkedLayer && enabled" >
      <l-polyLine
        :markers="markers"
        :focused="focusLink && focused"
      />
    </template>
    <template v-if="objectType === 'marker'">
      <l-marker
        v-for="marker in markers"
        :key="marker.id"
        :ref="marker.id"
        v-bind="marker"
        :lat-lng="marker.marker"
        :headers="headers[marker.headers]"
        @click="!marker.focus && $emit('click', marker)"
      />
    </template>
    <template v-if="objectType === 'circle'">
      <l-circle
        v-for="marker in markers"
        :key="marker.data.id"
        v-bind="marker"
        :lat-lng="marker.circle_marker"
        :headers="headers[marker.headers]"
      />
    </template>
  </l-layer-group>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
    },
    objectType: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    linked: {
      type: Boolean,
      default: false,
    },
    linkedLayer: {
      type: Boolean,
      default: false,
    },
    focusLink: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    travels: {
      type: Array,
      default: () => ([]),
    },
    markers: {
      type: Array,
      default: () => ([]),
    },
    headers: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
