<template>
  <div style="display: none;">
    <slot v-if="ready"/>
  </div>
</template>

<script>
import 'leaflet-polylinedecorator';
import _ from '@/misc/lodash';

import {
  findRealParent, OptionsMixin,
} from 'vue2-leaflet';
import L, { DomEvent } from 'leaflet';
import LLayerGroup from './LLayerGroup.vue';

/**
 * Symbol doesn't have rotate property so create ReverseArrow
 * @see https://github.com/bbecquet/Leaflet.PolylineDecorator/issues/28
 */
L.Symbol.ReverseArrow = L.Symbol.ArrowHead.extend({
  buildSymbol(dirPoint, latLngs, map, index, total) {
    const reverseDirPoint = {
      pt: dirPoint.pt,
      predecessor: dirPoint.predecessor,
      latLng: dirPoint.latLng,
      heading: (dirPoint.heading + 180) % 360,
    };
    // Call original buildSymbol method
    return L.Symbol.ArrowHead.prototype.buildSymbol
      .call(this, reverseDirPoint, latLngs, map, index, total);
  },
});
L.Symbol.reverseArrow = function init(options) {
  return new L.Symbol.ReverseArrow(options);
};

// Extend PolylineDecorator to add base polyline if options.line is an object
L.PolylineDecorator = L.PolylineDecorator.extend({
  setOptions(options) {
    this.options = options;
    this._patterns = this._initPatterns(this.options.patterns);
    this.redraw();
  },
  _draw() {
    if (typeof this.options.line === 'object' && this._paths.length > 0) {
      this.addLayer(L.polyline(this._paths[0], {
        color: 'black',
        weight: '2',
        ...this.options.line,
      }));
    }

    this._patterns
      .map((pattern) => this._getPatternLayers(pattern))
      .forEach((layers) => { this.addLayer(L.featureGroup(layers)); });
  },
});

export default {
  name: 'LPolyLine',
  extends: LLayerGroup,
  mixins: [OptionsMixin],
  props: {
    markers: {
      type: Array,
      default: () => ([]),
    },
    focused: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    coordinates() {
      if (Array.isArray(this.markers[0])) {
        return this.markers;
      } if (typeof this.markers[0].marker !== 'undefined'
        && Array.isArray(this.markers[0].marker)) {
        return this.markers.map(({ marker }) => ([...marker]));
      }
      return this.markers.map(({ lat, lng }) => ([lat, lng]));
    },
    lineOptions() {
      return {
        line: {
          color: this.focused ? 'red' : 'blue',
        },
        patterns: [
          {
            offset: 25,
            endOffset: 25,
            repeat: 50,
            symbol: L.Symbol.reverseArrow({
              pixelSize: 15,
              pathOptions: {
                fillOpacity: 1,
                weight: 0,
                color: this.focused ? 'red' : 'blue',
              },
            }),
          },
        ],
      };
    },
  },
  watch: {
    coordinates(markers, prevMarkers) {
      // If coordinates are really different
      if (!_.isEqual(markers, prevMarkers)) {
        this.mapObject.setPaths(markers);
      }
    },
    lineOptions(options) {
      this.mapObject.setOptions(options);
    },
  },
  mounted() {
    this.mapObject = L.polylineDecorator(
      this.coordinates,
      this.lineOptions,
    );
    DomEvent.on(this.mapObject, this.$listeners);
    this.ready = true;
    this.parentContainer = findRealParent(this.$parent);
    // If parent layer have function _originalAddLayer add the layer on parent layer (support layer)
    if (Object.hasOwnProperty.call(this.parentContainer.mapObject, '_originalAddLayer')) {
      this.parentContainer.mapObject._originalAddLayer(this.mapObject);
    } else {
      // Else add layer directly on map
      this.parentContainer.mapObject._map.addLayer(this.mapObject);
    }

    this.$nextTick(() => {
      this.$emit('ready', this.mapObject);
    });
  },
  beforeDestroy() {
    this.parentContainer.removeLayer(this);
  },
};
</script>
