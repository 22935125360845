/* eslint-disable global-require */
import Vue from 'vue';
import {
  LMap,
  LTileLayer,
  LControl,
  LControlLayers,
  LControlZoom,
  LControlScale,
  LPopup,
  LFeatureGroup,
} from 'vue2-leaflet';
import { Icon } from 'leaflet';
// ! Use a custom component to fix a bug, please look "@/components/map/LLocatecontrol.vue"
// ! for more information
// import Vue2LeafletLocatecontrol from 'vue2-leaflet-locatecontrol/Vue2LeafletLocatecontrol.vue';
import Vue2LeafletLocatecontrol from '@/components/map/LLocatecontrol.vue';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import VToCoordinates from '@ffly4u/vue2-leaflet-tocoordinates/VToCoordinates.vue';
import '@ffly4u/leaflet-tocoordinates/dist/leaflet.css';
import LPreferences from '@/components/map/LPreferences.vue';
import Vue2LeafletMarkerCluster from '@/components/map/markercluster-layerSupport.vue';
import LLayerGroup from '@/components/map/LLayerGroup.vue';
import LPolyLine from '@/components/map/LPolyLine.vue';
import LGoogleLayer from '@/components/map/LGoogleLayer.vue';
import LGeoman from '@/components/map/LGeoman.vue';
import LayerShapes from '@/components/map/LayerShapes.vue';
import LayerMarkers from '@/components/map/LayerMarkers.vue';
import LMarker from '@/components/map/LMarker.vue';
import LCircle from '@/components/map/LCircle.vue';
import LGeoJson from '@/components/map/LGeoJson.vue';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-circle', LCircle);
Vue.component('l-marker', LMarker);
Vue.component('l-popup', LPopup);
Vue.component('l-control', LControl);
Vue.component('l-control-layers', LControlLayers);
Vue.component('l-control-zoom', LControlZoom);
Vue.component('l-control-scale', LControlScale);
Vue.component('l-locatecontrol', Vue2LeafletLocatecontrol);
Vue.component('l-marker-cluster', Vue2LeafletMarkerCluster);
Vue.component('l-layer-group', LLayerGroup);
Vue.component('l-feature-group', LFeatureGroup);
Vue.component('l-geojson', LGeoJson);
Vue.component('l-to-coordinates', VToCoordinates);
Vue.component('l-polyLine', LPolyLine);
Vue.component('l-preferences', LPreferences);
Vue.component('l-google-layer', LGoogleLayer);
Vue.component('l-geoman', LGeoman);
Vue.component('layer-shapes', LayerShapes);
Vue.component('layer-markers', LayerMarkers);

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
