<template>
  <ul
    v-if="typeof data.id !== 'undefined'"
    style="list-style-type:none;padding-left:0px;"
  >
    <li
      v-for="(field) in fields"
      :key="`${data.id}-${field.fid}`"
    >
      <format-data
        :label="field.label"
        :value="field.value"
        :format="field.format"
      />
    </li>
  </ul>
</template>
<script>
import _ from '../../misc/dataAccess';
import FormatData from './FormatData.vue';

/**
 * Format data for popups with FormatData
 */
export default {
  name: 'DataPopup',
  components: {
    FormatData,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    /**
     * Compute fields data
     *
     * @return {Record<string, unknown>[]} Array of fields
     */
    fields() {
      const fields = [];
      for (let index = 0; index < this.headers.length; index += 1) {
        const {
          fid, path, label, type, format = {},
        } = this.headers[index];

        if (format.hide !== false && !['shape', 'marker', 'circle_marker'].includes(type)) {
          const value = _.get(this.data, path);

          if (value) {
            fields.push({
              fid,
              label,
              value,
              format,
            });
          }
        }
      }
      return fields;
    },
  },
};
</script>
