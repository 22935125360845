<template>
  <l-layer-group
    v-if="showLayersBasedonZoomLevelAndDeflate"
    ref="features"
    :name="label"
    :layerType="type"
    :enabled="enabled"
    :options="options"
  >
    <l-geojson
      v-for="shape in computedShapes"
      :key="shape.key"
      :geojson="shape.shape"
      v-bind="shape"
      :headers="headers[shape.headers]"
      :visible="true"
    />
  </l-layer-group>
</template>

<script>
import area from '@turf/area';

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    layerKey: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    shapes: {
      type: Array,
      default: () => ([]),
    },
    preferences: {
      type: Object,
      default: () => ({
        deflates: -1,
      }),
    },
    deflateOptions: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Object,
      default: () => ({}),
    },
    clustered: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Number,
      default: 8,
    },
  },
  computed: {
    computedShapes() {
      return this.shapes.map((hit) => ({
        ...hit,
        // Get surface in square meters
        surface: area(hit.shape),
      }))
        // Sort by size of surface (taller to smaller) to have the smallest above
        .sort((a, b) => b.surface - a.surface);
    },
    /**
     * Show layer if zoom level is greather than an arbritary value and deflates is equals to 0 o 1
     * @returns {Boolean} true if we shows shapes polygons false otherwise
     */
    showLayersBasedonZoomLevelAndDeflate() {
      return (this.zoom > 7) && (this.preferences[this.layerKey] > -1);
    },
  },
};
</script>
