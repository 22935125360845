<template>
  <o-geo-json
    ref="shape"
    :geojson="geojson"
    :options="options"
    :optionsStyle="options.cstyle"
    :visible="visible"
  >
    <l-popup
      v-if="headers.length > 0"
      :options="{ minWidth: 291 }"
    >
      <DataPopup
        :headers="headers"
        :data="data"
      />
    </l-popup>
  </o-geo-json>
</template>
<script>
import Vue from 'vue';
import { LGeoJson, findRealParent } from 'vue2-leaflet';
import DataPopup from '../crud/DataPopup.vue';

const DataPopupClass = Vue.extend(DataPopup);

export default {
  components: {
    'o-geo-json': LGeoJson,
  },
  props: {
    geojson: {
      type: [Object, Array],
      default: () => ({}),
    },
    color: {
      type: String,
    },
    icon: {
      type: String,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array,
      default: () => ([]),
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    /**
     * Compute options of Geojson
     *
     *```
     *{
     *  highlighted: boolean,
     *  pmIgnore: boolean,
     *  onEachFeature: function
     *  cstyle: { color: string, className: string },
     *  icon: {
     *    icon: string,
     *    classIcon: string,
     *    style: string,
     *    color: string,
     *  }
     *  highlight: unknown
     *}
     *```
     *
     * @return {Record<string, unknown>} Return options of marker
     */
    options() {
      const color = this.color || '#ff7800';
      const icon = this.icon || 'mdi mdi-vector-polygon';
      let style = '';
      const classIcon = ['div-marker'];

      if (this.highlight) {
        classIcon.push('highlight');
      }

      if (!icon.includes('mdi-map-marker')) {
        classIcon.push('marker-icon');
      }

      if (color.indexOf('#') === 0) {
        style = `background-color:${color}; border-color:${color};`;
      }

      const popupOptions = {};
      const parentObject = findRealParent(this.$parent).mapObject;
      if (this.headers.some((field) => (field.geo !== true))) {
        popupOptions.onEachFeature = (_feature, layer) => {
          // If one field of header have geo = true
          if (!layer._popup && parentObject) {
            layer.on('click', (e) => {
              // Prevent open when pm is enabled
              if (!this.pmEnabled) {
                const vm = new DataPopupClass({
                  i18n: this.$i18n,
                  store: this.$store,
                  router: this.$router,
                  propsData: {
                    data: this.data,
                    headers: this.headers,
                  },
                }).$mount();
                const popup = L.popup()
                  .setLatLng(e.latlng)
                  .setContent(vm.$el);
                // Just add a micro timeout for good render (compute correct size for popup)
                setTimeout(() => {
                  popup.openOn(parentObject._map);
                });
              }
            });
          }
        };
      }

      return {
        ...popupOptions,
        highlighted: this.highlight,
        pmIgnore: this.secondary,
        cstyle: {
          color,
          className: `shape-${color} ${this.highlight ? 'highlight' : ''}`,
        },
        icon: {
          icon,
          classIcon,
          style,
          color,
        },
        highlight: this.highlight,
      };
    },
  },
};
</script>

<style lang="scss">

</style>
