<template>
  <div
    class="leaflet-control leaflet-bar leaflet-preference"
  >
    <a class="leaflet-preference-toggle">
      <v-icon>settings</v-icon>
    </a>
    <section
      v-for="(group, scope) in mapPreferences"
      :key="scope"
      v-show="Object.keys(group).length > 0 || scope === 'tiles'"
    >
      <template
        v-if="scope === 'tiles'"
      >
        <h3>{{ $t(`map.title-${scope}`) }}</h3>
        <div>
          <label v-on:click.prevent="setTiles('roadmap')">
            <input
              type="checkbox"
              id="roadmap"
              name="tiles"
              value="roadmap"
              :checked="mapPreferences.tiles === 'roadmap' || mapPreferences.tiles === 'map'"
            >
            {{ $t('map.map') }}
          </label>
        </div>
        <div>
          <label v-on:click.prevent="setTiles('satellite')">
            <input
              type="checkbox"
              id="satellite"
              name="tiles"
              value="satellite"
              :checked="mapPreferences.tiles === 'satellite'"
            >
            {{ $t('map.satellite') }}
          </label>
        </div>
      </template>
      <template
        v-else-if="scope !== 'layers' || Object.keys(group).length > 1"
      >
        <h3>{{ $t(`map.title-${scope}`) }}</h3>
        <div
          v-for="(pref, key) in group"
          :key="key"
        >
          <label v-on:click.prevent="checkboxClicked(scope, key, $event, typeof pref === 'number')">
            <input
              type="checkbox"
              :name="key"
              v-bind.prop="checkboxState(pref, typeof pref === 'number')"
            >
            {{ $t(`map.${key}`) }}
          </label>
        </div>
      </template>
    </section>
    <section v-if="showClustersLegend">
      <template>
        <h3>{{ $t(`map.title-legend`) }}</h3>
        <div
          v-for="(enabled, title) in mapPreferences.layers"
          :key="`${title}-${enabled}`"
        >
          <label v-if="enabled">
            <v-icon :color="clusterIconColor(title)" small>mdi-circle</v-icon>
            {{ $t(`map.${title}`) }}
          </label>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import { LControl } from 'vue2-leaflet';

export default {
  name: 'LPreferences',
  extends: LControl,
  props: {
    mapPreferences: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    showClustersLegend() {
      if (!this.mapPreferences.layers) {
        return false;
      }
      let atLeastOneLayersVisible = false;
      Object.keys(this.mapPreferences.layers).forEach((layer) => {
        if (this.mapPreferences.layers[layer]) {
          atLeastOneLayersVisible = true;
        }
      });
      return this.mapPreferences.layers && atLeastOneLayersVisible;
    },
  },
  methods: {
    checkboxState(value, indeterminate = false) {
      if (indeterminate) {
        return {
          checked: value === 1,
          indeterminate: value === 0,
        };
      }
      return {
        checked: value,
      };
    },
    checkboxClicked(scope, key, event, indeterminate) {
      const target = event.target.control || event.target;
      let value = !target.checked;
      if (indeterminate) {
        value = -1;
        if (target.indeterminate) {
          value = 1;
        } else if (!target.checked) {
          value = 0;
        }
      }
      this.$emit('change', scope, key, value);
    },
    setTiles(tiles) {
      this.$emit('change', 'tiles', tiles);
    },
    clusterIconColor(title) {
      switch (title) {
        case 'device':
        case 'stateDevice':
          return 'blue';
        case 'sites':
          return 'red';
        case 'areas':
          return 'green';
        default:
          return 'purple';
      }
    },
  },
};
</script>

<style lang="scss">
.leaflet-preference {
  background-color: #ffffff;
  .leaflet-preference-toggle {
    width: 36px;
    height: 36px;
    line-height: 36px;
    & > i {
      font-size: 30px;
    }
  }
  section {
    display: none;
  }
  input[type="checkbox"] {
    // prevent event not captured by click on label
    pointer-events: none;
  }
  &:hover {
    padding: 6px;
    a {
      display: none;
    }
    section {
      display: block;
    }
  }
}
</style>
