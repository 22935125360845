<template>
  <div style="display: none;">
    <slot v-if="ready"/>
  </div>
</template>

<script>
import 'leaflet.markercluster';
import 'leaflet.markercluster.layersupport';
import 'leaflet.markercluster.freezable';
import {
  findRealParent, propsBinder, LayerGroupMixin, OptionsMixin,
} from 'vue2-leaflet';
import L, { DomEvent } from 'leaflet';

export default {
  name: 'markercluster',
  mixins: [LayerGroupMixin, OptionsMixin],
  props: {
    clustered: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: 'Cluster',
    },
  },
  data() {
    return {
      ready: false,
      spiderfied: false,
    };
  },
  watch: {
    clustered(value) {
      if (value) {
        this.mapObject.enableClustering();
      } else {
        this.mapObject.disableClustering();
      }
    },
  },
  mounted() {
    this.mapObject = L.markerClusterGroup.layerSupport(this.options);

    this.mapObject.on('clustermouseover', (e) => {
      if (!this.spiderfied) {
        this.mapObject.bindTooltip(this.name);
        this.mapObject.openTooltip(e.latlng);
      }
    });
    this.mapObject.on('clustermouseout', () => {
      this.mapObject.closeTooltip();
      this.mapObject.unbindTooltip();
    });
    this.mapObject.on('spiderfied', () => {
      this.$emit('spiderfied');
      this.spiderfied = true;
    });
    this.mapObject.on('unspiderfied', () => {
      this.$emit('unspiderfied');
      this.spiderfied = false;
    });
    DomEvent.on(this.mapObject, this.$listeners);
    propsBinder(this, this.mapObject, this.$options.props);
    this.ready = true;
    this.parentContainer = findRealParent(this.$parent);
    if (this.visible) {
      this.parentContainer.addLayer(this);
    }
    this.$nextTick(() => {
      this.$emit('ready', this.mapObject);
      // If clustering is not enabled when mounted, disabled clustering on start
      if (!this.clustered) {
        this.mapObject.disableClustering();
      }
    });
  },
  beforeDestroy() {
    this.parentContainer.removeLayer(this);
  },
};
</script>
