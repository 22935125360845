<template>
  <div style="display: none;">
    <slot v-if="ready"></slot>
  </div>
</template>

<script>
import '@geoman-io/leaflet-geoman-free';
import L from 'leaflet';
import area from '@turf/area';

L.Class.include({
  /**
   * Bring to front the smaller pm layers shapes
   * The other shapes layers are in back
   */
  pmBringToFront() {
    const pmLayers = [];
    this.eachLayer((layer) => {
      if (layer.pm && !layer._pmTempLayer && layer._bounds) {
        // Compute area size of bounds, to sort after
        pmLayers.push({
          layer,
          surface: area(layer.toGeoJSON()),
        });
      }
    });

    if (pmLayers.length) {
    // Sort pm layers to keep the smaller on top
      pmLayers.sort((a, b) => b.surface - a.surface).forEach(({ layer }) => {
        layer.bringToFront?.();
      });
    }
  },
  /**
   * Sort shape layers by area size
   * Sorted smaller are in top
   */
  sortShapeBySurface() {
    const layers = [];
    this.eachLayer((layer) => {
      if (!layer._pmTempLayer && layer._latlngs && layer._bounds) {
        // Compute area size of bounds, to sort after
        layers.push({
          layer,
          surface: area(layer.toGeoJSON()),
        });
      }
    });
    if (layers.length) {
      layers.sort((a, b) => b.surface - a.surface).forEach(({ layer }) => {
        layer.bringToFront?.();
      });
    }
  },
});

export default {
  name: 'vue2-leaflet-geoman',
  props: {
    lang: {
      type: String,
      default: 'en',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    editable: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    map: null,
    ready: false,
    cut: {},
  }),
  watch: {
    options(options) {
      this.map.pm.removeControls();
      this.addControls(options);
    },
    lang(lang) {
      this.map.pm.setLang(lang);
    },
  },
  mounted() {
    if (this.$parent._isMounted) {
      this.map = this.$parent.mapObject._map || this.$parent.mapObject;

      this.map.pmBringToFront();

      this.map.pm.setLang(this.lang);
      this.addControls(this.options);
      // listen to events
      this.map.on('pm:globaleditmodetoggled pm:globaldragmodetoggled pm:drawstart pm:drawend pm:globalremovalmodetoggled', this.pmToggle);
      this.map.on('pm:create pm:remove', this.layerEvent);

      this.map.on('layeradd', (e) => {
        // Prevent multiple call event layer
        if ((e.layer._latlng || e.layer._bounds) && e.layer.pm && !e.layer._pmTempLayer) {
          e.layer.on('pm:edit pm:dragend pm:cut', this.layerEvent);
        }
      });
    }
  },
  beforeDestroy() {
    this.map.pm.removeControls();
  },
  methods: {
    addControls(options) {
      this.map.pm.addControls({
        position: 'topleft',
        ...options,
      });
    },
    pmToggle(event) {
      const pmEnabled = event.enabled || event.type === 'pm:drawstart';
      if (this.editable === 'shapes') {
        if (pmEnabled) {
          // Ensure all pm shapes layers are in top
          this.map.pmBringToFront();
        } else {
          // Restore sort of shapes
          this.map.sortShapeBySurface();
        }
      }
      this.$emit('enabled', pmEnabled);
    },
    layerEvent(event) {
      // Save and shortcut the cut event to prevent multiple call with edit
      if (event.type === 'pm:cut') {
        if (typeof event.target.feature !== 'undefined') {
          event.layer.feature.properties = event.target.feature.properties;
        }
        this.cut = event;
        return;
      }
      if (this.cut.target && this.cut.target._leaflet_id === event.layer._leaflet_id) {
        // Restore original event type
        event.type = 'pm:cut';
        event.layer = this.cut.layer;
      }

      // add listeners on creation and delete on removal
      if (event.type === 'pm:create') {
        // add data
        event.layer.properties = {
          shape: event.shape,
        };

        // radius for circles
        if (event.shape === 'Circle') {
          event.layer.properties.radius = event.layer.getRadius();
        }
      }
      if (event.type === 'pm:remove') {
        event.layer.off(); // remove all event listeners
      }

      // emit event
      this.$emit('change', event);
    },
  },
};
</script>

<style lang="sass">
@import '~@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
</style>
