<template>
  <o-marker
    ref="marker"
    :lat-lng="latLng"
    :options="options"
    :icon="options.cicon"
    @click="$emit('click', $event)"
  >
    <l-popup
      v-if="headers.length > 0"
      :options="{ minWidth: 291 }"
    >
      <DataPopup
        :headers="headers"
        :data="data"
      />
    </l-popup>
  </o-marker>
</template>
<script>
import { LMarker } from 'vue2-leaflet';
import { DivIcon } from 'leaflet';
import DataPopup from '../crud/DataPopup.vue';

export default {
  components: {
    'o-marker': LMarker,
    DataPopup,
  },
  props: {
    latLng: {
      type: [Object, Array],
      default: null,
    },
    color: {
      type: String,
    },
    object: {
      type: String,
    },
    defaultAliasColor: {
      type: String,
      default: '',
    },
    defaultAliasIcon: {
      type: String,
    },
    icon: {
      type: String,
    },
    index: {
      type: Number,
    },
    radius: {
      type: Number,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array,
      default: () => ([]),
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    /**
     * Compute options of markers
     *
     *```
     *{
     *  highlighted: boolean,
     *  pmIgnore: boolean,
     *  properties: HitData,
     *  radius: number
     *  cicon: DivIcon
     *}
     *```
     *
     * @return {Record<string, unknown>} Return options of marker
     */
    options() {
      let icon = this.icon || 'mdi mdi-map-marker';
      let type = icon.includes('mdi-map-marker') ? 'marker' : 'icon';
      let color = this.color || '#0000ff';
      const iconFootColor = this?.defaultAliasColor || '#000000';
      if (type === 'icon' && !this.showIcon) {
        type = 'marker';
        icon = this.defaultAliasIcon ? this.defaultAliasIcon : 'mdi mdi-map-marker';
        color = this.defaultAliasColor || color;
      }
      const style = [
        `--marker-color: ${color}`,
        `--marker-icon-foot-color: ${iconFootColor}`,
      ];
      const classIcon = [
        'div-marker',
        `marker-${type}`,
      ];

      if (this.focus) {
        classIcon.push('focused');
      }
      if (this.highlight) {
        classIcon.push('highlight');
      }
      let iconDiv = {
        iconSize: [28, 28],
        html: `
        <div class="${classIcon.join(' ')}" style="${style.join('; ')}">
          <i class="${icon} marker-${color}"></i>
        </div>`,
      };

      if (icon.includes('ffly-pin')) {
        iconDiv = {
          className: icon,
          iconSize: [28, 37],
        };
      }
      return {
        highlighted: !!this.highlight,
        pmIgnore: !!this.secondary,
        properties: this.data,
        radius: this.radius,
        cicon: new DivIcon(iconDiv),
      };
    },
  },
};
</script>

<style lang="scss">
  @keyframes markerFocused {
    from {
      opacity: 0.6;
      text-shadow: 0 0 var(--marker-focus-color, #ffffff);
    }

    50%, to {
      opacity: 1;
      text-shadow: 0 0 8px var(--marker-focus-color, #ffffff);
    }
  }
  @keyframes iconFocused {
    from {
      opacity: 0.6;
      box-shadow: 0 0 0 0 var(--marker-focus-color, #ffffff);
    }

    50%, to {
      opacity: 1;
      box-shadow: 0 0 8px 2px var(--marker-focus-color, #ffffff);
    }
  }

  .div-marker {
    transition: transform ease .3s;
    i {
      font-size: 28px;
      position: absolute;
      top: -20px;
      color: var(--marker-color, currentColor);
    }
    &.focused {
      transform: scale(1.4);
      animation-duration: 0.8s;
      animation-direction: alternate;
      animation-iteration-count: infinite;
      &.marker-icon {
        // Correct position when transform scale
        top: -40px;
        animation-name: iconFocused;
      }
      &.marker-marker {
        animation-name: markerFocused;
      }
    }
    &.highlight::before {
      content: "";
      display: block;
      position: absolute;
      top: -8px;
      left: -2px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid blue;
    }
    &.marker-icon {
      position: absolute;
      top: -28px;
      left: 4px;
      border-color: var(--marker-color, currentColor);
      background-color: var(--marker-color, currentColor);
      height: 36px!important;
      width: 36px!important;
      padding: 3px;
      border-radius: 5px;
      &::after {
        content: '';
        position: absolute;
        top: 35px;
        left: 9px;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 16px 8px 0 8px;
        border-color: var(--marker-icon-foot-color) transparent transparent transparent;
      }
      i {
        position: static;
        display: block;
        height: 28px;
        width: 28px;
        line-height: 28px;
        color: #000000;
        background-color: #ffffff;
      }
    }
  }

  .marker-blue, .marker-discarded {
    color: blue;
  }

  .marker-closed {
    color: darkblue;
  }

  .marker-triggered {
    color: red;
  }

  .icon-number {
    font-weight: bold;
    color: blue;
    font-size: large;
    position: absolute;
    top: -35px;
    left: 10px;
  }
</style>
