import _ from '@/misc/lodash';
import dataAccess from '@/misc/dataAccess';

/**
  * Get Icon value
  *
  * @param {DataObject} object Data object of items
  * @param {Header} header Header with path for icon name
  *
  * @return {string}
  */
function getIcon(object, header) {
  const options = {
    alias: header.options.aliasIcons,
    defaultValue: header.options.defaultIcon,
  };
  const value = _.get(object, header.icon || 'icon', null);
  return dataAccess.getAliasValue(options, value);
}

/**
  * Get Color value
  *
  * @param {DataObject} object Data object of items
  * @param {Header} header Header with path for icon name
  *
  * @return {string}
  */
function getColor(object, header) {
  const options = {
    alias: header.options.aliasColors,
    defaultValue: header.options.defaultColor,
  };
  const value = _.get(object, header.color || 'color', null);
  return dataAccess.getAliasValue(options, value);
}

/**
 *  Format MarkerGeoData in MarkerGeoObject
 *
 * @param {MarkerGeoData} geoData Geographic data
 *
 * @return {MarkerGeoObject} Marker geoObject
 */
function buildMarker(geoData) {
  if (typeof geoData === 'undefined') {
    throw new Error('[buildMarker] geoData it\'s undefined');
  }

  if (typeof geoData !== 'object'
    || typeof geoData.latitude === 'undefined' || typeof geoData.longitude === 'undefined') {
    throw new Error('[buildMarker] geoData it\'s in invalid format');
  }

  const { latitude: lat, longitude: lng, radius } = geoData;
  return {
    marker: { lat, lng },
    radius,
  };
}

/**
 *  Format geoData in ShapeGeoObject
 *
 * @param {LongLatCoords[] | Geometry} geoData Geographic data
 *
 * @return {ShapeGeoObject}} Marker geoObject
 */
function buildShapes(geoData) {
  if (typeof geoData === 'undefined') {
    throw new Error('[buildShapes] geoData it\'s undefined');
  }

  let geometry = geoData;
  if (Array.isArray(geometry)) {
    geometry = {
      type: 'Polygon',
      coordinates: [geometry],
    };
  }

  if (typeof geometry.type === 'undefined') {
    throw new Error('[buildShapes] Geometry type are not defined');
  }
  return {
    shape: {
      type: 'Feature',
      geometry,
    },
  };
}

/**
 * Compute DataObject in geoObject
 *
 * @param {DataObject} object DataObject to compute in geoObject
 * @param {Header} _header Header of data
 * @param {boolean} [highlight] If item are highlighted
 *
 * @return {GeoObject}
 */
async function buildGeoObject(object, _header, highlight = false) {
  const emptyCoords = { latitude: null, longitude: null, radius: null };
  if (_.isEqual(object.coords, emptyCoords)) {
    return false;
  }
  const defaultHeader = {
    options: {
      aliasIcons: {},
      defaultIcon: false,
      aliasColors: {},
      defaultColor: false,
    },
  };
  const header = { ...defaultHeader, ..._header };

  let geoData = _.get(object, header.path);
  if (header.format) {
    geoData = await dataAccess.format(geoData, header.format);
  }

  if (geoData) {
    const baseGeoObject = {
      id: object.id || null,
      color: getColor(object, header),
      icon: getIcon(object, header),
      data: object,
      headers: header.alias || header.object,
      highlight,
      focus: object.focus,
      secondary: !!header.secondary,
    };
    if (header?.options?.defaultAliasColor) {
      baseGeoObject.defaultAliasColor = header.options.defaultAliasColor;
    }
    if (header?.options?.defaultAliasIcon) {
      baseGeoObject.defaultAliasIcon = header.options.defaultAliasIcon;
    }
    if (baseGeoObject.color === null && header?.options?.defaultAliasColor) {
      baseGeoObject.color = header.options.defaultAliasColor;
    }
    if (baseGeoObject.icon === null && header?.options?.defaultAliasIcon) {
      baseGeoObject.icon = header.options.defaultAliasIcon;
    }

    let geoObject = {};
    // Use try/catch to prevent fatal error
    try {
      // If is marker
      if (header.type === 'marker' || header.marker) {
        geoObject = buildMarker(geoData, object, header, highlight);
      } else if (header.type === 'shape' || header.shape) { // If is shape
        if (header.shape) {
          geoData = _.get(geoData, header.shape);
        }
        geoObject = buildShapes(geoData, object, header, highlight);
        geoObject = _.set(geoObject, 'shape.properties', object);
      }
      return { ...baseGeoObject, ...geoObject };
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      console.error('Bad geoData');
    }
  }
  return false;
}

/**
 * Compute an array of DataObjects in geoObjects
 *
 * @param {DataObject|DataObject[]} objects Array of DataObject to compute in geoObjects
 * @param {Header} header Header of data
 * @param {boolean} [highlight] If items are highlighted
 *
 * @returns {GeoObject|GeoObject[]} Array of geoObjects
 */
async function buildAllGeoObjects(objects, header, highlight = false) {
  // const startTime = performance.now();
  const items = objects;

  if (!Array.isArray(items)) {
    return buildGeoObject(objects, header, highlight);
  }

  // const promise = Promise.all(items.map((item) => buildGeoObject(item, header, highlight)));
  // const duration = performance.now() - startTime;
  // console.log(`someMethodIThinkMightBeSlow took ${duration}ms`);
  // return promise;
  return Promise.all(items.map((item) => buildGeoObject(item, header, highlight)));
}

export {
  buildMarker,
  buildShapes,
  buildGeoObject,
  buildAllGeoObjects,
};
