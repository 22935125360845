<template>
  <div style="display: none;">
    <slot v-if="ready"></slot>
  </div>
</template>

<script>
// Taken from https://github.com/jperelli/vue2-leaflet-googlemutant
// We added a way to dynamically update the type of layer.

import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';

import { findRealParent, propsBinder } from 'vue2-leaflet';

export default {
  name: 'LGoogleLayer',
  props: {
    options: {
      type: Object,
      default() { return {}; },
    },
    apikey: {
      type: String,
      default() { return ''; },
    },
    lang: {
      type: String,
      default: null,
    },
    region: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    layerType: {
      type: String,
      default: 'base',
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mapObject: {},
      parentContainer: {},
      ready: false,
    };
  },
  watch: {
    options() {
      this.mapObject._mutant.setMapTypeId(this.options.type);
      // Clear lru cache to ensure to use the new layer style
      this.mapObject._lru.clear();
      // Redraw layer to apply the new layer style
      this.mapObject.redraw();
    },
  },
  mounted() {
    this.mapObject = L.gridLayer.googleMutant(this.options);
    L.DomEvent.on(this.mapObject, this.$listeners);
    propsBinder(this, this.mapObject, this.$options.props);

    this.ready = true;
    this.parentContainer = findRealParent(this.$parent);

    this.loadScript().then(() => {
      this.parentContainer.addLayer(this, !this.visible);
    });

    // TODO move 'div.gm-style-cc' to '.leaflet-control-attribution.leaflet-control'
    // when they are ready
  },
  beforeDestroy() {
    this.parentContainer.removeLayer(this);
  },
  methods: {
    addLayer(layer, alreadyAdded) {
      if (!alreadyAdded) {
        this.mapObject.addLayer(layer.mapObject);
      }
    },
    removeLayer(layer, alreadyRemoved) {
      if (!alreadyRemoved) {
        this.mapObject.removeLayer(layer.mapObject);
      }
    },
    loadScript() {
      const oldScript = document.querySelector('head script[id="google-maps"]');
      if (!oldScript || (oldScript && oldScript.dataset.lang !== this.lang)) {
        if (oldScript) {
          oldScript.remove();
          delete window.google;
        }

        return new Promise((resolve, reject) => {
          const googleAPI = document.createElement('script');
          const scriptLanguage = this.lang ? `&language=${this.lang}` : '';
          const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${this.apikey}${scriptLanguage}`;

          googleAPI.setAttribute('src', scriptUrl);
          googleAPI.setAttribute('id', 'google-maps');
          googleAPI.setAttribute('data-lang', this.lang);
          googleAPI.onload = () => {
            resolve();
          };
          googleAPI.onerror = () => {
            reject();
          };
          document.head.appendChild(googleAPI);
        });
      }
      return new Promise((resolve) => resolve());
    },
  },
};
</script>
