<script>
import { LLayerGroup, LayerGroupMixin } from 'vue2-leaflet';
import L from 'leaflet';

/**
 * Custom implementation of Leaflet.Deflate
 * @see https://github.com/oliverroick/Leaflet.Deflate
 */
const deflateOptions = {
  minSize: 50,
  markerType: L.marker,
};

export default {
  extends: LLayerGroup,
  props: {
    keyLayer: {
      type: String,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    deflate: {
      type: Boolean,
      default: false,
    },
    deflateOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    layers: {},
  }),
  computed: {
    compDeflateOptions() {
      return { ...deflateOptions, ...this.deflateOptions };
    },
  },
  watch: {
    // When enabled change
    enabled(value) {
      if (value) {
        this.show(this);
      } else {
        this.hide(this);
      }
    },
    deflate(value) {
      if (value) {
        this._map.on('zoomend', this._deflate, this);
        this._map.on('moveend', this._deflate, this);
        this.updateDeflate();
      } else {
        this._map.off('zoomend', this._deflate, this);
        this._map.off('moveend', this._deflate, this);
        this.clearDeflateMarkers();
      }
    },
    compDeflateOptions() {
      if (this.deflate) {
        this.updateDeflate();
      }
    },
  },
  created() {
    // Copy original methods and bind with this
    this.origAddLayer = LayerGroupMixin.methods.addLayer.bind(this);
    this.origRemoveLayer = LayerGroupMixin.methods.removeLayer.bind(this);
  },
  mounted() {
    this.mapObject.keyLayer = this.keyLayer;

    this._map = this.mapObject._map;

    if (this.deflate) {
      this._map.on('zoomend', this._deflate, this);
      this._map.on('moveend', this._deflate, this);
    }
    // If group it's not enabled hide this
    if (!this.enabled) {
      this.hide(this);
    }
  },
  methods: {
    show(layer) {
      this.parentContainer.mapObject.addLayer(layer.mapObject);
    },
    hide(layer) {
      this.parentContainer.mapObject.removeLayer(layer.mapObject);
    },
    addLayer(layer, alreadyAdded) {
      // Call original addLayer
      this.origAddLayer(layer, alreadyAdded);
      if (this.deflate && this._validLayer(layer)) {
        this._prepLayer(layer);
        this.layers[this.getLayerId(layer)] = layer;

        if (layer.zoomState <= layer.zoomThreshold) {
          this.addDeflateMarker(layer);
        }
      }
    },
    removeLayer(layer, alreadyRemoved) {
      // Call original removeLayer
      this.origRemoveLayer(layer, alreadyRemoved);
      if (this.deflate) {
        delete this.layers[this.getLayerId(layer)];
        if (this.mapObject.hasLayer(layer.marker)) {
          this.mapObject.removeLayer(layer.marker);
        }
      }
    },
    getLayerId(layer) {
      return this.mapObject.getLayerId(layer);
    },
    hasLayer(layer) {
      return this.mapObject.hasLayer(layer);
    },
    addDeflateMarker(layer) {
      if (!this.hasLayer(layer.marker)) { // Prevent duplicate icons) {
        this.mapObject.addLayer(layer.marker);
      }
    },
    clearDeflateMarkers() {
      Object.values(this.layers).forEach((layer) => {
        if (this.hasLayer(layer.marker)) {
          this.mapObject.removeLayer(layer.marker);
        }
      });
    },
    updateDeflate() {
      this.clearDeflateMarkers();
      this.$children.forEach((layer) => {
        if (this._validLayer(layer)) {
          this._prepLayer(layer);
          if (typeof this.layers[this.getLayerId(layer)] === 'undefined') {
            this.layers[this.getLayerId(layer)] = layer;
          }
          if (layer.zoomState <= layer.zoomThreshold) {
            this.addDeflateMarker(layer);
          }
        }
      });
    },
    _validLayer(layer) {
      return (layer.mapObject || layer) instanceof L.FeatureGroup;
    },
    _prepLayer(layer) {
      layer.computedBounds = layer.getBounds();
      layer.zoomThreshold = this._getZoomThreshold(layer.computedBounds);
      layer.marker = this._makeMarker(layer);
      layer.zoomState = this._map.getZoom();
    },
    _deflate() {
      const bounds = this._map.getBounds();
      const endZoom = this._map.getZoom();
      Object.values(this.layers).forEach((layer) => {
        if (layer.marker && endZoom !== layer.zoomState
          && layer.computedBounds.intersects(bounds)) {
          if (endZoom <= layer.zoomThreshold) {
            this.addDeflateMarker(layer);
          } else if (this.hasLayer(layer.marker)) {
            this.mapObject.removeLayer(layer.marker);
          }
          layer.zoomState = endZoom;
        }
      });
    },
    _isCollapsed(bounds, zoom) {
      const northEastPixels = this._map.project(bounds.getNorthEast(), zoom);
      const southWestPixels = this._map.project(bounds.getSouthWest(), zoom);
      const width = Math.abs(northEastPixels.x - southWestPixels.x);
      const height = Math.abs(southWestPixels.y - northEastPixels.y);
      return (height < this.compDeflateOptions.minSize || width < this.compDeflateOptions.minSize);
    },
    _getZoomThreshold(bounds) {
      let zoomThershold;
      let zoom = this._map.getZoom();
      if (this._isCollapsed(bounds, zoom)) {
        while (!zoomThershold) {
          zoom += 1;
          if (!this._isCollapsed(bounds, zoom)) {
            zoomThershold = zoom - 1;
          }
        }
      } else {
        while (!zoomThershold) {
          zoom -= 1;
          if (this._isCollapsed(bounds, zoom)) {
            zoomThershold = zoom;
          }
        }
      }
      return zoomThershold;
    },
    _makeMarker(layer) {
      const geoJSON = [...layer.mapObject.getLayers()].pop();
      let { markerOptions } = this.compDeflateOptions;
      if (typeof markerOptions === 'function') {
        markerOptions = markerOptions(layer);
      }

      const marker = this.compDeflateOptions.markerType(
        layer.computedBounds.getCenter(),
        markerOptions,
      );

      this._bindEvents(marker, geoJSON);

      return marker;
    },
    _bindInfoTools(marker, parentLayer) {
      if (parentLayer._popupHandlersAdded) {
        marker.bindPopup(parentLayer._popup._content, parentLayer._popup.options);
      }

      if (parentLayer._tooltipHandlersAdded) {
        marker.bindTooltip(parentLayer._tooltip._content, parentLayer._tooltip.options);
      }
    },
    _bindEvents(marker, parentLayer) {
      const events = parentLayer._events;
      const eventKeys = events ? Object.getOwnPropertyNames(events) : [];
      const eventParents = parentLayer._eventParents;
      const eventParentKeys = eventParents ? Object.getOwnPropertyNames(eventParents) : [];

      this._bindInfoTools(marker, parentLayer);

      for (let i = 0, lenI = eventKeys.length; i < lenI; i += 1) {
        const listeners = events[eventKeys[i]];
        for (let j = 0, lenJ = listeners.length; j < lenJ; j += 1) {
          marker.on(eventKeys[i], listeners[j].fn);
        }
      }

      // For FeatureGroups we need to bind all events, tooltips and popups
      // from the FeatureGroup to each marker
      if (!parentLayer._eventParents) { return; }

      for (let i = 0, lenI = eventParentKeys.length; i < lenI; i += 1) {
        if (!parentLayer._eventParents[eventParentKeys[i]]._map) {
          this._bindEvents(marker, parentLayer._eventParents[eventParentKeys[i]]);

          // We're copying all layers of a FeatureGroup, so we need to bind
          // all tooltips and popups to the original feature.
          this._bindInfoTools(parentLayer, parentLayer._eventParents[eventParentKeys[i]]);
        }
      }
    },
  },
};
</script>
