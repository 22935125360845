<template>
  <o-circle
    ref="marker"
    :lat-lng="latLng"
    :radius="radius"
    :options="options"
    :icon="options.cicon"
  >
    <l-popup v-if="headers.length > 0">
      <DataPopup
        :headers="headers"
        :data="data"
      />
    </l-popup>
  </o-circle>
</template>
<script>
import {
  LCircle,
} from 'vue2-leaflet';
import DataPopup from '../crud/DataPopup.vue';

export default {
  components: {
    'o-circle': LCircle,
    DataPopup,
  },
  props: {
    latLng: {
      type: [Object, Array],
      default: null,
    },
    color: {
      type: String,
    },
    icon: {
      type: String,
    },
    index: {
      type: Number,
    },
    radius: {
      type: Number,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    /**
     * Compute options of circle
     *
     * @see https://leafletjs.com/reference-1.7.1.html#circle-option
     *
     * @param {Record<string, any>} marker object of marker
     *
     * @return {Record<string, any>} Return options of marker
     */
    options() {
      const circleOptions = {};
      if (this.data.confident === false) {
        circleOptions.color = '#8e8e8e';
        circleOptions.fillColor = '#8e8e8e';
      }
      return circleOptions;
    },
  },
  mounted() {
    this.mapObject = this.$refs.marker.mapObject;
  },
};
</script>
