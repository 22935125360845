<template>
  <router-link
    v-if="realType === 'linkObject' && externalLink === false"
    :to="content.url"
  >
    <v-icon
      small
      :title="content.label"
    >
      mdi-eye
    </v-icon>
    <!-- {{ content.label }} -->
  </router-link>
  <router-link
    v-else-if="type === 'link' && externalLink === false"
    :to="content.url"
  >
    {{ content.label }}
  </router-link>
  <a
    v-else-if="type === 'link' && externalLink === true"
    :href="content.url"
    target="_blank"
  >
    <v-icon
      v-if="linkIcon"
    >
      {{ linkIcon }}
    </v-icon>
    <span v-else>{{ content.label }}</span>
  </a>
  <v-icon
    v-else-if="type === 'icon'"
  >
    {{ content.value }}
  </v-icon>
  <span v-else-if="content !== null">
    <template v-if="content.label">
      {{ content.label }}:
    </template>
    {{ content.value }}
  </span>
</template>
<script>
import dataAccess from '@/misc/dataAccess';

/**
 * This component format the data asynchronously with dataAccess
 * He manage translation of value internaly
 */
export default {
  name: 'FormatData',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      default: '',
    },
    format: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    content: null,
    type: false,
    externalLink: false,
    // For legacy support external link
    realType: null,
    linkIcon: null,
  }),
  watch: {
    /**
     * Call formatContent when locale change
     */
    '$i18n.locale': {
      handler() {
        this.formatContent();
      },
    },
    /**
     * Call formatContent when value change and when it's initialized
     */
    value: {
      handler() {
        this.formatContent();
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * Format the data asynchronously and update state whith result
     */
    formatContent() {
      dataAccess.format(this.value, this.format).then((value) => {
        /**
         * If format have type
         * The type is added only when format is ready, to prevent error
         */
        if (typeof this.format.type !== 'undefined') {
          this.type = this.format.type.indexOf('link') !== -1 ? 'link' : this.format.type;
          // For legacy support external link
          this.realType = this.format.type;
        }

        let label = false;

        if (typeof this.format.label !== 'undefined') {
          label = this.$t(this.format.label);
        } else if (this.label !== '') {
          label = this.$t(this.label);
        }
        if (this.format.linkIcon) {
          this.linkIcon = this.format.linkIcon;
        }

        // If is link check if is externalLink
        if (this.type === 'link') {
          if (typeof this.format.isExternal !== 'undefined') {
            this.externalLink = this.format.isExternal;
          } else { // For legacy support external link
            console.warn('Legacy support: isExternal must be defined in format for \'link\' types');
            if (this.realType === 'linkStreetview') {
              this.externalLink = true;
            }
          }

          if (label === false) {
            label = this.$t('misc.goTo');
          }

          if (!value) {
            this.type = 'null';
            this.content = null;
          } else {
            this.content = {
              url: value,
              label,
            };
          }
        } else {
          this.content = {
            value,
            label,
          };
        }
      });
    },
  },
};
</script>
