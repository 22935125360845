<template>
  <div>
    <l-map
      :style="style"
      :zoom="zoom"
      :center="center"
      v-on:moveend="updateMapDebounce"
      ref="map"
      :options="{zoomControl: false}"
      :minZoom="3"
      :maxZoom="maxZoom"
      :maxBounds="[[-90,180],[90,-180]]"
      v-on:popupclose="popupClosed"
    >
      <div :key="'interface'+$i18n.locale">
        <l-control-zoom
          position="topleft"
          :zoomInTitle="$t('map.zoomIn')"
          :zoomOutTitle="$t('map.zoomOut')"/>
        <l-locatecontrol :options="locateControlOptions"/>
        <l-to-coordinates :options="toCoordinatesOptions"/>
        <l-control position="topleft" class="loading">
          <div v-if="loading">
            <v-progress-circular
              :size="22"
              indeterminate
              color="primary"
              :title="$t('misc.loading')"
            />
          </div>
        </l-control>
        <l-google-layer
          :apikey="googleAPIKey"
          :lang="$i18n.locale"
          :options="layersOptions" />
      </div>
      <l-geoman
        v-if="editable"
        :editable="editable"
        :lang="$i18n.locale"
        :options="geomanOptions"
        @enabled="pmToggle"
        @change="pmEdit"
      />
      <l-preferences
        position="topright"
        :mapPreferences="preferences"
        v-on:change="changePreference"
      />
      <l-control-scale
        position="bottomleft"
        :imperial="true"
        :metric="true"
      />
      <l-circle
        v-if="showRadius"
        :lat-lng="showRadius.marker"
        :radius="showRadius.radius"
        :options="showRadius.options"
        v-bind="showRadius.optionsCirle"
      />
      <l-marker-cluster
        v-for='(layer, key) in layersMarkers'
        :ref="`${key}MarkersClusters`"
        :key="`${key}MarkersClusters`"
        :name="clusterTitle(key)"
        :options="getClusterOptions(key)"
        :clustered="preferences.markers.clustered || false"
      >
        <layer-markers
          :ref="`${key}Markers`"
          v-bind="layer"
          :headers="formattedHeaders"
          :linked="linked"
          :focusLink="focusLink"
          @click="click"
        />
      </l-marker-cluster>
      <template>
        <l-marker-cluster
          v-for="(layer, key) in shapesMarkers"
          :key="`${key}-cluster`"
          :ref="`shapesClusters-${key}`"
          :name="$t(`map.${key}`)"
          :options="shapesClusterOptions[key]"
          :clustered="preferences.markers.clustered || false"
        >

          <layer-markers
            :ref="`shapesMarkers-${key}`"
            v-bind="shapesMarkers[key]"
            :headers="formattedHeaders"
            :linked="linked"
            :focusLink="focusLink"
            :show="preferences.layers[key] && preferences.shapes[key] !== 0"
            @click="click"
          />
        </l-marker-cluster>
      </template>
      <layer-shapes
        v-for="layer in layersShapes"
        v-bind="layer"
        :key="layer.key"
        :layer-key="layer.key"
        :shapes="shapes[layer.key]"
        :preferences="preferences.shapes"
        :deflateOptions="deflateOptions"
        :headers="formattedHeaders"
        :clustered="preferences.markers.clustered || false"
        :zoom="zoom"
      />
      <l-marker
        v-for="marker in extraMarkers"
        :key="marker.data.id"
        :ref="`ex_${marker.data.id}`"
        :lat-lng="marker.marker"
        v-bind="marker"
        :headers="formattedHeaders[marker.headers]"
      />
      <l-feature-group
        ref="editable"
        v-if="editable !== '' && computedValue !== null"
        enabled
      >
        <l-geojson
          v-if="editable === 'shapes'"
          :geojson="computedValue.shape"
        />
        <l-marker
          v-else-if="editable === 'markers'"
          :lat-lng="computedValue.marker"
        />
      </l-feature-group>
    </l-map>
    <div
      v-show="fullLoading"
      class="full-loading"
    >
      <div class="background"></div>
      <div class="text">{{ $t('misc.loadingPleaseWait') }}</div>
    </div>
  </div>
</template>

<script>
import L, { DivIcon } from 'leaflet';
import '@/plugins/vueLeaflet';
import { buildMarker, buildShapes, buildAllGeoObjects } from '@/misc/buildGeoObject';
import dataAccess from '@/misc/dataAccess';
import _ from '@/misc/lodash';
import { mapGetters } from 'vuex';
import { capitalizeFirstLetter, ObjHasKey } from '@/misc/utils';

let allowUserToDisableClusters = true;
let clusteredChangedByCode = false;

function maxClusterRadius(zoomLevel) {
  // From level 17 clusterRadius reduce to 10px
  if (zoomLevel > 17) {
    return 10;
  }
  // If zoomLevel greater than 17, keep default radius
  return 80;
}

const genClusterOptions = (clusterColor) => ({
  maxClusterRadius,
  iconCreateFunction: (cluster) => {
    const childCount = cluster.getChildCount();
    let className = 'marker-cluster ';
    let color = 'marker-cluster-';
    if (childCount < 10) {
      color += `small ffly-${clusterColor}`;
    } else if (childCount < 100) {
      color += `medium ffly-${clusterColor}`;
    } else {
      color += `large ffly-${clusterColor}`;
    }
    className += color;

    if (cluster.getAllChildMarkers()
      .findIndex(({ options: { highlighted } }) => highlighted) !== -1) {
      className += ' haveHighlighted';
    }

    return new DivIcon({
      iconSize: [40, 40],
      className,
      html: `<div><span>${childCount}</span></div>`,
    });
  },
});

export default {
  props: {
    headers: {
      type: [Object, Array],
      default: () => ([]),
    },
    mapLayers: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: [Object, Array],
      default: () => ([]),
    },
    hits: {
      type: Array,
      default: () => ([]),
    },
    create: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fullLoading: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Object,
      default: () => ({
        lat: 0,
        lng: 0,
        zoom: 3,
        bounds: null,
      }),
    },
    maxZoom: {
      type: Number,
      default: 22,
    },
    maxAutoZoom: {
      type: Number,
      default: 19,
    },
    editable: {
      type: String,
      default: '',
    },
    height: {
      type: [String, Number],
      default: 0,
    },
    linked: {
      type: Boolean,
      default: false,
    },
    focusLink: {
      type: Boolean,
      default: false,
    },
    autoZoom: {
      type: Boolean,
      default: false,
    },
    defaultAutoZoom: {
      type: Boolean,
      default: false,
    },
    clustered: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    map: null,
    initialized: false,
    ratioDistance: 0,
    center: [0, 0],
    zoom: 0,
    bounds: null,
    googleAPIKey: process.env.VUE_APP_GOOGLEKEY,
    showRadius: null,
    lineShape: null,
    extraMarkers: [],
    clusterOptions: {
      maxClusterRadius,
      iconCreateFunction(cluster) {
        const childCount = cluster.getChildCount();
        let className = 'marker-cluster ';
        let color = 'marker-cluster-';
        if (childCount < 10) {
          color += 'small ffly-blue';
        } else if (childCount < 100) {
          color += 'medium ffly-blue';
        } else {
          color += 'large ffly-blue';
        }
        className += color;

        if (cluster.getAllChildMarkers()
          .findIndex(({ options: { highlighted } }) => highlighted) !== -1) {
          className += ' haveHighlighted';
        }

        return new DivIcon({
          iconSize: [40, 40],
          className,
          html: `<div><span>${childCount}</span></div>`,
        });
      },
      // spiderfyOnMaxZoom: false,
      // disableClusteringAtZoom: 17,
    },
    clustersOptionsCache: {},
    shapesClusterOptions: {
      sites: {
        maxClusterRadius,
        iconCreateFunction(cluster) {
          const childCount = cluster.getChildCount();
          let className = 'marker-cluster ';
          let color = 'marker-cluster-';
          if (childCount < 10) {
            color += 'small ffly-shape';
          } else if (childCount < 100) {
            color += 'medium ffly-shape';
          } else {
            color += 'large ffly-shape';
          }
          className += color;
          if (cluster.getAllChildMarkers()
            .findIndex(({ options: { highlighted } }) => highlighted) !== -1) {
            className += ' haveHighlighted';
          }
          return new DivIcon({
            iconSize: [40, 40],
            className,
            html: `<div><span>${childCount}</span></div>`,
          });
        },
      },
      areas: {
        maxClusterRadius,
        iconCreateFunction(cluster) {
          const childCount = cluster.getChildCount();
          let className = 'marker-cluster ';
          let color = 'marker-cluster-';
          if (childCount < 10) {
            color += 'small ffly-green';
          } else if (childCount < 100) {
            color += 'medium ffly-green';
          } else {
            color += 'large ffly-green';
          }
          className += color;
          if (cluster.getAllChildMarkers()
            .findIndex(({ options: { highlighted } }) => highlighted) !== -1) {
            className += ' haveHighlighted';
          }
          return new DivIcon({
            iconSize: [40, 40],
            className,
            html: `<div><span>${childCount}</span></div>`,
          });
        },
      },
    },
    keyPref: 'home',
    preferences: {
      tiles: 'map',
      layers: {},
      map: {},
      markers: {},
      shapes: {},
    },
    deflateOptions: {
      minSize: 50,
      // Marker build with marker options for class highlight
      markerOptions: ({ options: { icon, highlight } }) => ({
        icon: new DivIcon({
          iconSize: [38, 38],
          html: `<div class="${icon.classIcon.join(' ')}" style="${icon.style}"><i class="${icon.icon}"></i></div>`,
          className: `leaflet-div-icon ${highlight ? 'highlight' : ''}`,
        }),
      }),
    },
    hitChange: false,
    pmEnabled: false,
    previousEvent: null,
    layersHits: [],
    loadLayersDebounced: () => {},
    updateMapDebounce: () => {},
    allowUserToDisableClusters: true,
    clusteredChangedByCode: false,
    maxUnclusteredShapes: 55,
    biggestBounds: {},
  }),
  computed: {
    ...mapGetters(['shapesMarkers']),
    /**
     * Compute style of map
     *
     * @return {string} Return style of map
     */
    style() {
      const style = ['width: 100%'];
      if (this.preferences.tiles === 'roadmap') {
        // Overload the marker focus color in darken color to improve visibility on roadmap tiles
        style.push('--marker-focus-color: #2c2c2c');
      }
      // Parse string height
      const height = Number.isNaN(Number(this.height))
        ? Number(this.height.replace('px', ''))
        : this.height;

      if (Number.isNaN(height) || height <= 0) {
        if (this.$route.path.includes('item/business-events')) {
          // const height = !Number.isNaN(this.height) ? this.height - 189 : 279;
          // return Math.max(height, 279);
          // return !Number.isNaN(this.height) ? this.height - 189 : 279;
          switch (this.$vuetify.breakpoint.name) {
            case 'xs':
              style.push('height: calc(100vh - 86px)');
              break;
            case 'sm':
            case 'md':
            case 'lg':
            case 'xl':
            default:
              style.push('height: calc(100vh - 134px)');
              break;
          }
        } else if (this.keyPref === 'home') {
          // const height = !Number.isNaN(this.height) ? this.height - 189 : 279;
          // return Math.max(height, 279);
          // return !Number.isNaN(this.height) ? this.height - 189 : 279;
          switch (this.$vuetify.breakpoint.name) {
            case 'xs':
              style.push('height: calc(100vh - 106px)');
              break;
            case 'sm':
            case 'md':
            case 'lg':
            case 'xl':
            default:
              style.push('height: calc(100vh - 154px)');
              break;
          }
        } else {
          switch (this.$vuetify.breakpoint.name) {
            case 'xs':
              style.push('height: calc(100vh - 56px)');
              break;
            case 'sm':
            case 'md':
            case 'lg':
            case 'xl':
            default:
              style.push('height: calc(100vh - 64px)');
              break;
          }
        }
      } else if (this.$route.path.includes('item/business-events')) {
        style.push(`height: ${height - 70}px`);
      } else if (this.keyPref === 'home') {
        style.push(`height: ${height - 90}px`);
      } else {
        style.push(`height: ${height}px`);
      }
      return style.join('; ');
    },
    /**
     * Merge hits and maplayers headers
     *
     * @return {{[key: string]: Record<string, unknown>[]}}
     */
    formattedHeaders() {
      if (Array.isArray(this.headers) && this.headers.length > 0) {
        return {
          default: this.headers,
        };
      }
      return this.mapLayers.reduce((acc, { alias, object, fields }) => ({
        ...acc,
        [alias || object]: fields,
      }), { ...this.headers });
    },
    /**
     * Compute layers options
     *
     * For information about the style
     * @see https://developers.google.com/maps/documentation/javascript/styling
     *
     *```
     *{
     *  maxNativeZoom: number,
     *  maxZoom: number,
     *  type: string,
     *  styles: style[]
     *}
     *```
     *
     * @return {Record<string, unknown>} Return layers options
     */
    layersOptions() {
      return {
        maxNativeZoom: 18,
        maxZoom: this.maxZoom,
        type: this.preferences.tiles || 'roadmap',
        styles: [ // styles are not taken into account in Google Maps dev mode
          { // we disable poi to avoid having to much info on the map
            featureType: 'poi',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
        ],
      };
    },
    /**
     * Compute list of layers
     *
     * @return {Record<string, unknown>[]} Return array of layers
     */
    layers() {
      const layers = [];
      let headers = Object.entries(this.headers);
      // If header is array copy header on default key
      if (Array.isArray(this.headers) && this.headers.length > 0) {
        headers = [['default', this.headers]];
      }

      headers = headers.concat(this.mapLayers.map((layer) => (
        [layer.alias || layer.object, layer]
      )));

      const headersLength = headers.length;
      // Set type only if more than one layers, to hide controls when only one layer
      const typeLayer = layers.length + headersLength > 1 ? 'overlay' : undefined;
      for (let i = 0; i < headersLength; i += 1) {
        const [key, fields] = headers[i];
        // Get Field layer
        let fieldLayer = fields;
        if (!_.isEmpty(fieldLayer)) {
          if (Array.isArray(fields)) {
          // Find field with marker, shape or circle_marker
            fieldLayer = fields.find((field) => {
            // Condition for new format
              if (['marker', 'shape', 'circle_marker'].includes(field.type)) {
                return true;
              }
              // fallback legacy format
              return (field.marker || field.shape || field.circle_marker);
            });
          }
          let objectType = fieldLayer.type || false;

          // If fieldLayer found and obectType are not defined
          if (objectType === false && typeof fieldLayer !== 'undefined') {
          // check type of fieldLayer
            if (typeof fieldLayer.marker !== 'undefined') {
              objectType = 'marker';
            } else if (typeof fieldLayer.shape !== 'undefined') {
              objectType = 'shape';
            } else if (typeof fieldLayer.circle_marker !== 'undefined') {
              objectType = 'circle_marker';
            }
          }

          // If Object type found
          if (objectType !== false) {
            let enabled = (typeof fieldLayer.enableLayer === 'boolean') ? fieldLayer.enableLayer : true;

            if (this.preferences.layers && typeof this.preferences.layers[key] !== 'undefined') {
              enabled = this.preferences.layers[key];
              // if (value === true || value > 0) {
              //   enabled = true;
              // } else if (value === false || value < 0) {
              //   enabled = false;
              // } else if (value === 0) {
              //   if (fieldLayer.options
              //   && (fieldLayer.options.minZoom || fieldLayer.options.maxZoom)) {
              //     if (fieldLayer.options.minZoom < this.zoom
              //     || fieldLayer.options.maxZoom > this.zoom) {
              //       enabled = false;
              //     } else {
              //       enabled = true;
              //     }
              //   }
              // }
            }
            const layerShape = ObjHasKey(fieldLayer, 'layerShape') ? fieldLayer.layerShape : 0;

            layers.push({
              key,
              linkedLayer: fieldLayer.linkedLayer,
              showLayer: fieldLayer.showLayer || true,
              layerShape,
              labelLayer: fieldLayer.labelLayer,
              label: this.$t(fieldLayer.labelLayer || key),
              type: typeLayer,
              enabled,
              focused: false,
              objectType,
              options: {
                data: fieldLayer.options,
              // data: {
              //   minZoom: 18,
              //   maxZoom: 3,
              // },
              },
            });
          }
        } else {
          // console.error(`layer fields of "${key}" are empty`);
          // if (process.env.NODE_ENV !== 'production') {
          //   console.log(headers[i]);
          // }
        }
      }
      return layers;
    },
    /**
     * Compute list of layers of shapes
     *
     * @return {Record<string, unknown>[]} Return array of layers of shapes
     */
    layersShapes() {
      return this.layers.filter(({ objectType }) => objectType === 'shape') || [];
    },
    /**
     * Compute list of layers of markers
     *
     * @return {Record<string, unknown>[]} Return array of layers of markers
     */
    layersMarkers() {
      // Reduce for layers of type marker or circle_marker
      const layers = {};
      const layersLength = this.layers.length;
      for (let i = 0; i < layersLength; i += 1) {
        const layer = this.layers[i];
        if (['marker', 'circle_marker'].includes(layer.objectType)) {
          layers[layer.key] = {
            ...layer,
            travels: [],
            markers: [],
          };
        }
      }

      const hits = [...this.hits, ...this.layersHits];
      const hitsLength = hits.length;
      let inTravel = false;
      let travelId = null;
      for (let i = 0; i < hitsLength; i += 1) {
        // Spread hit to prevent side effect
        const hit = { ...hits[i] };
        // If hit header is not defined and headers is array
        if (typeof hit.headers === 'undefined' && Array.isArray(this.headers)) {
          hit.headers = 'default';
        }
        // Only if layer exist
        if (typeof layers[hit.headers] !== 'undefined') {
          if (hit.focus) {
            layers[hit.headers].focused = true;
          }
          if (hit.data.travel === true) {
            if (!inTravel) {
              inTravel = true;
              layers[hit.headers].travels.push({
                focused: false,
                markers: [],
              });
              travelId = layers[hit.headers].travels.length - 1;
            }
            if (hit.focus) {
              layers[hit.headers].travels[travelId].focused = true;
            }
            layers[hit.headers].travels[travelId].markers.push(hit.marker);
          } else if (hit.data.travel === false) {
            if (travelId === null || inTravel) {
              layers[hit.headers].travels.push({
                focused: false,
                markers: (this.hits[i - 1]) ? [this.hits[i - 1].marker] : [],
              });
              travelId = layers[hit.headers].travels.length - 1;
            }
            layers[hit.headers].travels[travelId].markers.push(hit.marker);
            if (this.hits[i + 1] && this.hits[i + 1].data.travel) {
              layers[hit.headers].travels[travelId].markers.push(this.hits[i + 1].marker);
            }
            inTravel = false;
          }
          hit.object = hit.headers;
          hit.showIcon = this.preferences.shapes[hit.object] === true;
          // if (this.object === 'beacons') {
          //   hit.color = ''
          // }
          if (this.bounds !== null) {
            // this optimisation is disabled because the highlight on marker click need's the marker
            // to be on the map when the component receive the highlight event
            // if (this.bounds.contains(hit.marker)) {
            layers[hit.headers].markers.push(hit);
            // }
          } else {
            layers[hit.headers].markers.push(hit);
          }
        }
      }
      return layers;
    },
    /**
     * Compute list of markers
     *
     * @return {Record<string, unknown>[]} Return array of markers
     */
    markers() {
      // Spread hit de prevent side effect
      return [...this.hits, ...this.layersHits].map(({ ...hit }) => {
        if (hit.marker) {
          if (!hit.headers && Array.isArray(this.headers)) {
            hit.headers = 'default';
          }
          return hit;
        }
        return false;
      }).filter((hit) => (hit));
    },
    /**
     * Compute list of shape
     *
     * @return {Record<string, unknown>[]} Return array of shape objects
     */
    shapes() {
      const hits = [...this.hits, ...this.layersHits];
      const shapes = {};
      const hitsLength = hits.length;
      for (let i = 0; i < hitsLength; i += 1) {
        const hit = hits[i];
        if (hit.shape) {
          // If hit header is not defined and headers is array
          if (typeof hit.headers === 'undefined' && Array.isArray(this.headers)) {
            hit.headers = 'default';
          }
          // Force recreate deflate
          hit.key = `${hit.data.id}_${hit.highlight || false}`;

          if (typeof shapes[hit.headers] === 'undefined') {
            shapes[hit.headers] = [];
          }
          shapes[hit.headers].push(hit);
        }
      }

      this.calculateLengthAndManageClustering(shapes);
      if (shapes && Object.keys(shapes)) {
        this.$store.dispatch('GENERATE_MARKERS', shapes);
      }
      return shapes;
    },
    /**
     * Find index of focused marker or false if no marker focused
     *
     * @return {number|boolean}
     */
    markersFocus() {
      const markerIndex = this.markers.findIndex(({ focus }) => focus);
      return markerIndex !== -1 && markerIndex;
    },
    /**
     * Get userPreferences from Auth store
     *
     * @return {Record<string, unknown>} Return object of userPreferences
     */
    userPreferences() {
      const preferences = this.$store.state.auth.currentUserffly4u._source.mapPreferences || {};
      return preferences[this.keyPref] || {};
    },
    /**
     * Compute locateControl Options with translation
     *
     * @return {Record<string, unknown>}
     */
    locateControlOptions() {
      return {
        strings: {
          title: this.$t('map.whereIam'),
          metersUnit: this.$t('map.metersUnit'),
          feetUnit: this.$t('map.feetUnit'),
          popup: this.$t('map.locationPopup'),
          outsideMapBoundsMsg: this.$t('map.outsideMapBoundsMsg'),
        },
      };
    },
    /**
     * Compute toCoordinates Options with translation
     *
     * @return {Record<string, unknown>}
     */
    toCoordinatesOptions() {
      return {
        position: 'topleft',
        inputLabel: this.$t('map.enterGPSlocation'),
      };
    },
    /**
     * Compute configuration for geoman plugin
     *
     * @return {Record<string, unknown>} Return configuration for geoman plugin
     */
    geomanOptions() {
      return {
        drawMarker: this.editable === 'markers' && this.create,
        drawCircle: false,
        drawCircleMarker: false,
        drawPolyline: false,
        drawRectangle: false,
        drawPolygon: this.editable === 'shapes' && this.create,
        cutPolygon: this.editable === 'shapes',
        dragMode: this.editable === 'shapes',
        editMode: true,
        removalMode: false,
      };
    },
    /**
     * Compute geoObject from value
     *
     * @return {MarkerGeoObject|ShapeGeoObject}
     */
    computedValue() {
      let geoObject = null;
      if (!_.isEmpty(this.value)) {
        switch (this.editable) {
          case 'shapes':
            geoObject = buildShapes(this.value);
            break;

          case 'markers':
            geoObject = buildMarker(this.value);
            break;

          default:
            if (process.env.NODE_ENV !== 'production') {
              console.log(`The editable type "${this.editable}" is not valid`);
            }
            break;
        }
      }
      return geoObject;
    },
    showShapesMarkers() {
      switch (this.preferences.shapes.deflates) {
        case 0:
          return this.zoom < 12;
        default:
          return true;
      }
    },
    deviceClusterTitle() {
      if (this.keyPref.includes('item/business-events')) {
        return this.$t('map.stateDevice');
      }
      return this.preferences.layers.stateDevice ? this.$t('map.stateDevice') : this.$t('map.device');
    },
  },
  watch: {
    initialized: {
      handler() {
        this.loadLayersDebounced();
      },
      immediate: true,
    },
    bounds() {
      this.loadLayersDebounced();
    },
    /**
     * Update map state when hits change
     *
     * Update bounds and refresh clusters
     */
    hits: {
      handler() {
        this.hitChange = true;
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.fitBounds();
          });
        });
      },
      immediate: true,
    },
    /**
     * Update markers focus state when markerFocus change
     *
     * @param {boolean|number} current index of current marker focused or false
     * @param {boolean|number} prev index of previous marker focused or false
     */
    markersFocus(current, prev) {
      if (prev !== false) {
        this.unFocusMarker(this.markers[prev]);
      }
      if (current !== false) {
        this.focusMarker(this.markers[current]);
      }
    },
    /**
     * Update deflate options when preferences change
     */
    'preferences.shapes.deflates': function deflates(value) {
      if (typeof this.$refs.features !== 'undefined') {
        if (value === 1) {
          this.deflateOptions.minSize = Infinity;
        } else {
          this.deflateOptions.minSize = 50;
        }
      }
    },
  },
  /**
   * Initialize map data
   */
  created() {
    const {
      lat,
      lng,
      zoom,
      bounds,
    } = this.state;

    this.center = [
      Math.min(90, Math.max(-90, lat || 0)),
      Math.min(180, Math.max(-180, lng || 0)),
    ];
    this.zoom = Math.max(3, zoom || 3);
    this.bounds = bounds;

    let currentPath = this.$router.currentRoute.matched[0].path;
    if (currentPath.length === 0) {
      currentPath = 'home';
    }
    this.keyPref = currentPath;
  },
  /**
   * When component is mounted compute the preferences and initialize map
   */
  mounted() {
    this.loadLayersDebounced = _.debounce(this.loadLayers, 500);
    this.updateMapDebounce = _.debounce((event) => {
      if (this.map === event.target) {
        this.updateMap();
      }
    }, 500);
    const preferences = {
      tiles: 'roadmap',
      layers: {},
      map: {},
      markers: {},
      shapes: {},
    };

    if (this.autoZoom) {
      preferences.map.autoZoom = this.defaultAutoZoom;
    }

    if (this.clustered) {
      preferences.markers.clustered = true;
    }

    if (!this.editable && this.layers.some(({ objectType }) => (objectType === 'shape'))) {
      // preferences.shapes.deflates = 0;
    }

    this.layers.forEach((layer) => {
      let value = (typeof layer.enableLayer === 'boolean') ? layer.enableLayer : true;

      if (layer.options.data) {
        if (typeof layer.options.data.minZoom !== 'undefined'
          || typeof layer.options.data.maxZoom !== 'undefined') {
          value = 0;
        }
      }

      value = (typeof layer.showLayer === 'boolean') ? layer.showLayer : true;

      preferences.layers[layer.labelLayer || layer.key] = value;
      preferences.shapes[layer.labelLayer || layer.key] = layer.layerShape;
    });

    // Compute userPreferences
    Object.keys(preferences).forEach((preference) => {
      if (typeof this.userPreferences[preference] === 'string') {
        preferences[preference] = this.userPreferences[preference];
      } else if (typeof this.userPreferences[preference] !== 'undefined') {
        // we need to do this to avoid having old preferences showing up
        Object.keys(preferences[preference]).forEach((p) => {
          if (typeof this.userPreferences[preference][p] !== 'undefined') {
            preferences[preference][p] = this.userPreferences[preference][p];
          }
        });
      }
    });

    this.preferences = { ...preferences };
    this.deflateOptions.minSize = this.preferences.shapes.deflates === 1 ? Infinity : 50;

    if (this.$refs.map && this.$refs.map.mapObject) {
      this.map = this.$refs.map.mapObject;
      this.fitBounds();
      this.$nextTick(() => {
        this.updateMap(true);
        this.$nextTick(() => {
          this.initialized = true;
        });
      });
    }
  },
  /**
   * When component is updated define hitChange to false
   */
  updated() {
    if (this.hitChange) {
      this.hitChange = false;
    }
    if (this.editable === 'shapes') {
      if (this.previousEvent !== null && this.previousEvent.type === 'pm:cut') {
        /**
           * In case of cut event the layer change of id
           * and it's not replaced by data update (duplicate).
           * So need to remove then manually.
           */
        this.$refs.map.mapObject.removeLayer(this.previousEvent.layer);
      }
      this.$refs.map.mapObject.sortShapeBySurface();
    }
  },
  methods: {
    clusterTitle(key) {
      if (key === 'device') {
        return this.deviceClusterTitle;
      }
      return this.$t(`map.${key}`);
    },
    /**
     * Generate clusters options with cluster color fetched from config file
     */
    getClusterOptions(key) {
      if (this.clustersOptionsCache?.[key]) {
        return this.clustersOptionsCache[key];
      }
      const layer = this.mapLayers?.find((l) => l.alias === key || l.object === key);
      this.clustersOptionsCache[key] = layer?.clusterColor ? genClusterOptions(layer.clusterColor) : genClusterOptions('blue');
      return this.clustersOptionsCache[key];
    },
    capitalizeFirstLetter,
    /**
     * Load map layers data
     */
    loadLayers() {
      if (this.initialized && this.bounds !== null) {
        this.$nextTick(async () => {
          let makeRequest = false;
          const layers = this.mapLayers.map(async (layer) => {
            // const lName = layer.alias || layer.object;
            // checks if the request bounds are a subset of a previous request
            // if so, no more request is done
            // if (this.biggestBounds[lName]) {
            //   if (!this.biggestBounds[lName].contains(this.bounds)) {
            //     this.biggestBounds[lName] = this.bounds;
            //     makeRequest = true;
            //   }
            // } else {
            //   this.biggestBounds[lName] = this.bounds;
            //   makeRequest = true;
            // }
            makeRequest = true;
            let body;
            if (makeRequest) {
              let limit;
              if (layer.queryParams) {
                ({ limit } = layer.queryParams);
              }
              const requestBody = {
                ...layer.queryParams,
                filters: {
                  spatial: [
                    Object.values(this.bounds.getNorthEast()).reverse(),
                    Object.values(this.bounds.getNorthWest()).reverse(),
                    Object.values(this.bounds.getSouthWest()).reverse(),
                    Object.values(this.bounds.getSouthEast()).reverse(),
                  ],
                },
              };
              if (this.zoom <= 10 && requestBody?.level === 'SITE') {
                requestBody.centroid = true;
              }
              ({ body } = await this.$store.dispatch('crud/SEARCH', {
                object: layer.object,
                alias: layer.alias || layer.object,
                body: requestBody,
                all: typeof limit === 'undefined' && true,
                store: false,
              }));
            } else {
              body = undefined;
            }

            if (typeof body !== 'undefined') {
              // Push Computed geoObject
              return buildAllGeoObjects(body, layer);
            }
            return new Promise((resolve) => { resolve([]); });
          });
          if (makeRequest) {
            const layersHits = (await Promise.all(layers))
              .reduce((acc, hits) => [...acc, ...hits], []);
            // filter Layershits (in a web worker thread) and return only unique hits
            this.$worker.run((layersHits_) => {
              const unique = [];
              const distinct = [];
              let id;
              for (let i = 0; i < layersHits_.length; i += 1) {
                id = parseInt(layersHits_[i].id, 10);
                if (!unique[id]) {
                  distinct.push(layersHits_[i]);
                  unique[id] = 1;
                }
              }
              return distinct;
            }, [layersHits])
              .then((distinctLayersHits) => {
                this.layersHits = distinctLayersHits;
              });
          }
        });
      }
    },
    /**
     * Fit map bounds to see all hits
     */
    fitBounds() {
      // const hasClusters = typeof this.$refs.deviceMarkersClusters !== 'undefined';
      let hasClusters = false;
      if (this.layersMarkers) {
        Object.keys(this.layersMarkers).forEach((layer) => {
          // eslint-disable-next-line prefer-template
          const name = layer + 'MarkersClusters';
          if (typeof this.$refs?.[name]?.[0] !== 'undefined') {
            hasClusters = true;
          }
        });
      }
      if (this.zoom === 3) {
        const bounds = L.latLngBounds([]);
        if (typeof this.$refs.editable !== 'undefined') {
          bounds.extend(this.$refs.editable.mapObject.getBounds());
        } else if (hasClusters) {
          Object.keys(this.layersMarkers).forEach((layer) => {
            // eslint-disable-next-line prefer-template
            const name = layer + 'MarkersClusters';
            if (typeof this.$refs?.[name]?.[0] !== 'undefined') {
              bounds.extend(this.$refs[name][0].mapObject.getBounds());
            }
          });
        }
        // } else if (hasClusters && typeof this.$refs.deviceMarkersClusters !== 'undefined') {
        //   bounds.extend(this.$refs.deviceMarkersClusters[0].mapObject.getBounds());
        // }

        if (bounds.isValid()) {
          this.$refs.map.mapObject.fitBounds(bounds);
        }
      }
      // Refresh Cluster icons
      if (this.clustered && hasClusters) {
        Object.keys(this.layersMarkers).forEach((layer) => {
          // eslint-disable-next-line prefer-template
          const name = layer + 'MarkersClusters';
          if (typeof this.$refs?.[name]?.[0] !== 'undefined') {
            this.$refs[name][0].mapObject.refreshClusters();
          }
        });
        Object.keys(this.shapes).forEach((shape) => {
          // eslint-disable-next-line prefer-template
          const name = 'shapesClusters-' + shape;
          if (typeof this.$refs?.[name]?.[0] !== 'undefined') {
            this.$refs[name][0].mapObject.refreshClusters();
          }
        });
      }
    },
    /**
     * Update map state
     *
     * update zoomLevel, center and bounds
     * update visibility of layer relative to zoomLevel
     * emit updateMap event to parent
     *
     * @param {boolean} [force] define if force the update
     */
    updateMap(force = false) {
      const zoom = this.map.getZoom();
      const bounds = this.map.getBounds();
      const { lat, lng } = this.map.getCenter();
      let doUpdate = force;
      if (zoom !== this.zoom) {
        doUpdate = true;
      } else {
        // Get maximum distance
        const distance = Math.max(
          Math.abs(this.center.lat - lat),
          Math.abs(this.center.lng - lng),
        );

        // If distance is greater than 1/4 of ratio
        if (this.ratioDistance < distance) {
          doUpdate = true;
        }
      }

      // Send update when is necessary
      if (doUpdate) {
        // Get minimum ratio distance
        this.ratioDistance = Math.min(
          Math.abs(bounds._northEast.lat - bounds._southWest.lat),
          Math.abs(bounds._northEast.lng - bounds._southWest.lng),
        ) / 4;

        this.zoom = zoom;
        this.center = { lat, lng };
        this.bounds = bounds;
        this.$emit('update:state', {
          lat,
          lng,
          zoom,
          bounds,
        });

        const layers = ['features', 'markers', 'shapesMarkers'];
        layers.forEach((l) => {
          if (this.$refs[l]) {
            this.$refs[l].forEach((layer) => {
              if (this.preferences.layers[layer.$vnode.key] === 0) {
                if (layer.options.data
                  && (layer.options.data.minZoom || layer.options.data.maxZoom)) {
                  if (layer.options.data.minZoom < zoom || layer.options.data.maxZoom > zoom) {
                    layer.hide(layer);
                  } else {
                    layer.show(layer);
                  }
                }
              }
            });
          }
        });
      }
    },
    /**
     * Toggle status of pm edit to handle interaction with the other layers
     *
     * @param {Boolean} enabled State of pmToggle
     */
    pmToggle(enabled) {
      this.pmEnabled = enabled;
    },
    /**
     * Emit GeoJSON of pmEdit
     *
     * @param {Event} e Event of pmEdit
     */
    pmEdit(e) {
      this.previousEvent = e;
      const event = e.type.replace('pm:', '');

      let coordinates = false;
      if (this.editable === 'shapes') {
        // Preserve pm shapes layers on top when are edited
        this.$nextTick(() => {
          this.map.pmBringToFront();
        });
        const { geometry } = e.layer.toGeoJSON();
        ([coordinates] = geometry.coordinates);
      } else if (this.editable === 'markers') {
        // Convert object properties names
        const { lat: latitude, lng: longitude } = e.layer.getLatLng();
        coordinates = { latitude, longitude };
      }

      // If coordinates it's not false
      if (coordinates !== false) {
        const radius = dataAccess.get(e.layer, 'options.radius');
        if (radius) {
          coordinates = [coordinates, radius];
        }

        this.$emit('edit', {
          event,
          coordinates,
        });
      }
    },
    /**
     * Update showRadius with hit object
     *
     * @param {Object} hit hit object
     */
    setShowRadius(hit) {
      if (hit) {
        this.showRadius = hit;
      } else {
        this.showRadius = null;
      }
    },
    /**
     * Called when marker are Clicked
     *
     * @param {object} data data of marker
     */
    click(data) {
      if (data && data.id) {
        this.$emit('click', data.id);
      }
    },
    /**
     * Called when popup is closed
     *
     * @param {Event} event map event
     */
    popupClosed(event) {
      // Ensure keep popup open when hits had updated with marker focused
      // (due to recreate markers when computed has updated)
      if (this.hitChange && this.markersFocus !== false) {
        this.$nextTick(() => {
          this.focusMarker(this.markers[this.markersFocus], true);
        });
        return;
      }
      // Check if contain options in popup
      if (event.popup && event.popup._source && event.popup._source.options) {
        const markerId = dataAccess.get(event.popup._source.options, 'properties.id', null);
        // Get marker to check if is already focused
        // prevent reopen when is closed programmatically
        const marker = this.markers.find(({ id }) => (id === markerId));
        if (markerId !== null && marker && marker.focus) {
          this.$emit('click', markerId);
        }
      }
    },
    getMarker(id) {
      let marker;
      if ((this.$refs.deviceMarkers || []).length > 0) {
        const [markerLayer] = this.$refs.deviceMarkers;
        if (typeof markerLayer !== 'undefined' && markerLayer.$refs[id] && markerLayer.$refs[id][0]) {
          ({ marker } = markerLayer.$refs[id][0].$refs);
        }
      }
      Object.keys(this.shapes).forEach((shape) => {
        // eslint-disable-next-line prefer-template
        const name = 'shapesMarkers-' + shape;
        if (!marker && (this.$refs?.[name]?.[0] || []).length > 0) {
          const [markerLayer] = this.$refs[name][0];
          if (typeof markerLayer !== 'undefined' && markerLayer.$refs[id] && markerLayer.$refs[id][0]) {
            ({ marker } = markerLayer.$refs[id][0].$refs);
          }
        }
      });
      return marker || false;
    },
    /**
     * Center the marker, zoom if is necessary and if autoZoom it's true
     *
     * @param {Object} mapObject mapObject of marker
     * @param {Boolean} autoZoom Define if does zoom on marker
     *
     * @return {Boolean} The marker is visible
     */
    async showMarker(mapObject, autoZoom = false) {
      let centeredTo = mapObject;
      let clustered = false;

      // If marker is grouped (markercluster)
      if (mapObject.__parent && mapObject.__parent._group) {
        const clusterMarker = mapObject.__parent;
        clustered = clusterMarker._zoom >= this.zoom;

        // If autoZoom is enabled and zoom is not maximum
        if (autoZoom && this.zoom !== this.maxAutoZoom) {
          // If is spiderify on maxAutoZoom
          if (clustered) {
          // Zoom at maximum on cluster
            this.map.setView(mapObject.getLatLng(), this.maxAutoZoom);
            return false;
          }
          // Is not already visible
          if (!this.map.hasLayer(mapObject)) {
            // Need await promise to prevent async zoom
            // Zoom at necessary level to show marker
            await new Promise(
              (resolve) => clusterMarker._group.zoomToShowLayer(mapObject, resolve),
            );
          }
          this.zoom = this.maxAutoZoom;
        } else if (!this.map.hasLayer(mapObject) && clustered) {
          // If marker is not visible center to cluster marker
          centeredTo = clusterMarker;
        }
      }

      // We make sure it's centered
      this.map.setView(centeredTo.getLatLng(), this.zoom);
      return this.map.hasLayer(mapObject) && !clustered;
    },
    /**
     * Focus the marker
     *
     * @param {Object} marker Marker object
     * @param {Boolean} openPopup If force to open popup
     */
    async focusMarker(marker, openPopup = false) {
      const markerComponent = this.getMarker(marker.id);
      if (markerComponent !== false) {
        // Await move and zoom to know if marker is visible
        const visibleMarker = await this.showMarker(
          markerComponent.mapObject,
          this.preferences.map.autoZoom,
        );

        this.$nextTick(() => {
          if (!visibleMarker) {
            // Add extra marker out of cluster
            this.extraMarkers = [{
              ...marker,
              options: { ...marker.options, zIndexOffset: 200 },
            }];
          } else if (this.extraMarkers.length) {
            this.extraMarkers = [];
          }

          if (openPopup) {
            if (!visibleMarker) {
              this.$nextTick(() => {
                if (this.$refs[`ex_${marker.id}`].length === 1 && this.$refs[`ex_${marker.id}`][0].mapObject) {
                  this.$refs[`ex_${marker.id}`][0].mapObject.openPopup();
                }
              });
            } else {
              markerComponent.mapObject.openPopup();
            }
          }
          this.setShowRadius(marker);
        });
      }
    },
    /**
     * Unfocus the marker
     * Close popup, remove show radius and remove extraMarkers
     *
     * @param {Object} marker object of marker
     */
    unFocusMarker(marker) {
      const markerComponent = this.getMarker(marker.id);
      if (markerComponent !== false) {
        // Ensure to close popup for un focused marker
        markerComponent.mapObject.closePopup();
      }
      this.setShowRadius(false);
      this.extraMarkers = [];
    },
    /**
     * Update preference with new value
     *
     * @param {string} scope scope of preference to updated
     * @param {string} key key of preference to updated
     * @param {number|boolean} [value] New value of preference
     */
    changePreference(scope, key, value) {
      if (scope === 'markers' && key === 'clustered' && !value && !allowUserToDisableClusters) {
        const msg = {
          id: null,
          title: 'Info',
          description: this.$t('misc.cannotDisableClusters'),
          color: 'default',
          timeout: 5000,
        };
        this.$store.commit('snackbars/ADD_MESSAGE', msg);
        return;
      }
      if (scope === 'tiles') {
        // this.preferences[scope] = key;
        this.$set(this.preferences, scope, key);
      } else {
        let newScope = this.preferences[scope];
        newScope = { ...newScope, [key]: value };
        // this.preferences[scope][key] = value;
        this.$set(this.preferences, scope, newScope);
      }

      this.updatePreferences({
        ...this.preferences,
      });

      // this.$nextTick(() => {
      //   this.updateMap(true);
      // });
    },
    /**
     * Update the preferences of map
     * Merge new preferences with previous
     *
     * @param {Object} preferences object of preferences
     */
    updatePreferences(preferences) {
      const userPreferences = this.$store.state.auth.currentUserffly4u._source.mapPreferences || {};
      this.$store.dispatch('auth/UPDATE_SELF', {
        mapPreferences: {
          ...userPreferences,
          [this.keyPref]: preferences,
        },
      });
    },
    /**
     * if markers's count on the map is greather than a selected value forcefully
     * enable clusters to avoid * performence issues. And disallow user to
     *  activated them back
     */
    maybeForceEnableClusters(length) {
      if (length >= this.maxUnclusteredShapes) {
        allowUserToDisableClusters = false;
        if (this.preferences.markers && this.preferences.markers.clustered === false) {
          this.changePreference('markers', 'clustered', true);
          clusteredChangedByCode = true;
        }
      } else if (clusteredChangedByCode) {
        allowUserToDisableClusters = true;
        this.changePreference('markers', 'clustered', false);
        clusteredChangedByCode = false;
      }
      if (length < this.maxUnclusteredShapes) {
        allowUserToDisableClusters = true;
      }
    },
    calculateLengthAndManageClustering(shapesObj) {
      this.$worker.run((shapes) => {
        let length = 0;
        if (shapes && Object.keys(shapes)) {
          Object.keys(shapes).forEach((key) => { length += shapes[key].length; });
        }
        return length;
      }, [shapesObj])
        .then((length) => {
          this.maybeForceEnableClusters(length);
        });
    },
  },
};
</script>

<style lang="scss">
 .leaflet-fade-anim .leaflet-tile,.leaflet-zoom-anim .leaflet-zoom-animated {
   will-change:auto !important;
  }

  .leaflet-pane {
    z-index: 4;
  }

  .leaflet-control input {
    width: auto;
  }

  .leaflet-popup-content-wrapper {
    background-color: rgba(255,255,255,.9);
  }

  .leaflet-div-icon {
    background-color: transparent;
    border: none !important;
  }

  [class^="shape-"] {
    opacity: 0.65;
  }

  [class^="shape-"].highlight {
    fill:rgb(51, 136, 255);
    stroke:rgb(51, 136, 255);
    stroke-width: 4;
  }

  .loading {
    height: 30px;
    width: 30px;
    & > div {
      background: white;
      border: 2px solid rgba(0,0,0,0.2);
      padding: 2px;
    }
  }

  .full-loading {
    .background {
      position: absolute;
      z-index: 10000;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      opacity: 0.5;
    }
    .text {
      position: absolute;
      top: 50%;
      z-index: 10001;
      left: 45%;
      font-weight: bold;
    }
  }

  .marker-cluster.haveHighlighted {
    background-color: rgba(127, 179, 252, 0.6);
    div {
      background-color: rgba(51, 136, 255, 0.6);
    }
  }

  .marker-cluster-small {
    background-color: rgba(179, 202, 248, 0.767);
  }
  .marker-cluster-small div {
    background-color: rgba(163, 199, 245, 0.6);
  }
  .marker-cluster-medium {
    background-color: rgba(98, 147, 238, 0.671);
  }
  .marker-cluster-medium div {
    background-color: rgba(98, 175, 247, 0.7);
  }
  .marker-cluster-large {
    background-color: rgba(27, 136, 238, 0.6);
  }
  .marker-cluster-large div {
    background-color: rgba(12, 107, 248, 0.384);
  }

  .marker-cluster-small.ffly-blue {
    background-color: rgba(179, 202, 248, 0.767);
  }
  .marker-cluster-small.ffly-blue div {
    background-color: rgba(163, 199, 245, 0.6);
  }
  .marker-cluster-medium.ffly-blue {
    background-color: rgba(98, 147, 238, 0.671);
  }
  .marker-cluster-medium.ffly-blue div {
    background-color: rgba(98, 175, 247, 0.7);
  }
  .marker-cluster-large.ffly-blue {
    background-color: rgba(27, 136, 238, 0.6);
  }
  .marker-cluster-large.ffly-blue div {
    background-color: rgba(12, 107, 248, 0.384);
  }

  .marker-cluster-small.ffly-red {
    background-color: rgba(248, 139, 139, 0.767);
  }
  .marker-cluster-small.ffly-red div {
    background-color: rgba(250, 125, 125, 0.6);
  }
  .marker-cluster-medium.ffly-red {
    background-color: rgba(252, 100, 100, 0.671);
  }
  .marker-cluster-medium.ffly-red div {
    background-color: rgba(245, 49, 49, 0.7);
  }
  .marker-cluster-large.ffly-red {
    background-color: rgba(177, 10, 10, 0.6);
  }
  .marker-cluster-large.ffly-red div {
    background-color: rgba(240, 12, 12, 0.671);
  }

  .marker-cluster-small.ffly-shape {
    background-color: rgba(248, 139, 139, 0.767);
  }
  .marker-cluster-small.ffly-shape div {
    background-color: rgba(250, 125, 125, 0.6);
  }
  .marker-cluster-medium.ffly-shape {
    background-color: rgba(252, 100, 100, 0.671);
  }
  .marker-cluster-medium.ffly-shape div {
    background-color: rgba(245, 49, 49, 0.7);
  }
  .marker-cluster-large.ffly-shape {
    background-color: rgba(177, 10, 10, 0.6);
  }
  .marker-cluster-large.ffly-shape div {
    background-color: rgba(240, 12, 12, 0.671);
  }

  .marker-cluster-small.ffly-area {
    background-color: rgba(139, 248, 139, 0.767);
  }
  .marker-cluster-small.ffly-area div {
    background-color: rgba(125, 250, 125, 0.6);
  }
  .marker-cluster-medium.ffly-area {
    background-color: rgba(100, 252, 100, 0.671);
  }
  .marker-cluster-medium.ffly-area div {
    background-color: rgba(49, 245, 49, 0.7);
  }
  .marker-cluster-large.ffly-area {
    background-color: rgba(10, 177, 10, 0.6);
  }
  .marker-cluster-large.ffly-area div {
    background-color: rgba(12, 240, 12, 0.671);
  }

  .marker-cluster-small.ffly-green {
    background-color: rgba(139, 248, 139, 0.767);
  }
  .marker-cluster-small.ffly-green div {
    background-color: rgba(125, 250, 125, 0.6);
  }
  .marker-cluster-medium.ffly-green {
    background-color: rgba(100, 252, 100, 0.671);
  }
  .marker-cluster-medium.ffly-green div {
    background-color: rgba(49, 245, 49, 0.7);
  }
  .marker-cluster-large.ffly-green {
    background-color: rgba(10, 177, 10, 0.6);
  }
  .marker-cluster-large.ffly-green div {
    background-color: rgba(12, 240, 12, 0.671);
  }

  .marker-cluster-small.ffly-purple {
    background-color: rgba(215, 152, 255, 0.767);
  }
  .marker-cluster-small.ffly-purple div {
    background-color: rgba(215, 152, 255, 0.3);
  }
  .marker-cluster-medium.ffly-purple {
    background-color: rgba(184, 102, 238, 0.767);
  }
  .marker-cluster-medium.ffly-purple div {
    background-color: rgba(184, 102, 238, 0.3);
  }
  .marker-cluster-large.ffly-purple {
    background-color: rgba(163, 67, 226, 0.667);
  }
  .marker-cluster-large.ffly-purple div {
    background-color: rgba(163, 67, 226, 0.3);
  }

</style>
